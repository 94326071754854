import { graphql } from "gatsby"
// import Lightbox from 'react-image-lightbox'

import React from "react"
import styled from "styled-components"
import { BackgroundImage } from "../components/Images"
import { LogoHeading } from "../components/Layout/Heading"
import Layout, { Container } from "../components/Layout/Layout"
import SEO from "../components/Layout/Seo"

const isDev = process.env.NODE_ENV === "development"
const siteUrl = isDev
  ? "http://localhost:8000"
  : "https://www.littlelambsschool.org"

const BlogPost = props => {
  const { markdownRemark: post } = props.data // data.markdownRemark holds our post data
  const {
    html,
    excerpt,
    frontmatter: { date, path, title, type, image },
  } = post
  const featuredImageSrc = siteUrl + "/" + image
  const postUrl = siteUrl + path
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        meta={[
          { property: "og:title", content: post.frontmatter.title },
          { property: "og:description", content: post.excerpt },
          { property: "og:image", content: featuredImageSrc },
          { property: "og:url", content: postUrl },
        ]}
      />
      <BackgroundImage />
      <LogoHeading />
      <Container>
        {post.frontmatter.title && <h2>{post.frontmatter.title}</h2>}
        {post.frontmatter.type == "post" && <h6>{post.frontmatter.date}</h6>}
        <br />
        {/* <h6>Tags: {post.frontmatter.tags.join(", ")} </h6> */}
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
        <Img src={featuredImageSrc} />

        {/* {lightboxOpen && (
          <Lightbox
            mainSrc={images[selectedImage].src}
            nextSrc={images[(selectedImage + 1) % images.length].src}
            prevSrc={
              images[(selectedImage + images.length - 1) % images.length].src
            }
            onCloseRequest={() => setState({ lightboxOpen: false })}
            onMovePrevRequest={() =>
              setState({
                selectedImage:
                  (selectedImage + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              setState({
                selectedImage: (selectedImage + 1) % images.length,
              })
            }
          />
        )} */}
      </Container>
    </Layout>
  )
}

export default BlogPost

const Img = styled.img`
  width: calc(100% - 16px);
  max-width: 500px;
  margin: 0;
  /* @media (max-width: 991px) {
    width: 45%;
  } */
  /* @media (max-width: 767px) {
    width: calc(100% - 16px);
  } */
  animation: fadeIn 1s;
`

export const pageQuery = graphql`
  query BlogPostByPath($markdownPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $markdownPath } }) {
      html
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        type
        image
      }
    }
  }
`
